const common = {
    state: {
        loginid: sessionStorage.getItem("loginid") || '',
        penum: sessionStorage.getItem("penum") || '',
        displayname: sessionStorage.getItem("displayname") || '',
        headerPedetail: sessionStorage.getItem("headerPedetail") || null,
        lang: sessionStorage.getItem("lang") || 'en',
        menuFuntionAuth: sessionStorage.getItem("menuFuntionAuth") || null,
        selfregister: sessionStorage.getItem("selfregister") || false,
        ispemgr: false,
        isOperate: false,
        upLimit: sessionStorage.getItem("upLimit") || 0,
        authGroupFuncList: {},
        suffix: '',
        maptype: sessionStorage.getItem("maptype") || '',
        switch: '',
        smsresidue: '',
        voiceresidue: '',
        theme: localStorage.getItem("theme313fm") || 'light',
        isMenuCollapse: sessionStorage.getItem('isMenuCollapse') || false,
        filterRecordsPage: {},
        isSafetyIncidentFlashing: sessionStorage.getItem('safetyIncidentFlashing') || false
    },
    mutations: {
        SET_SWITCH: (state, data) => {
            state.switch = data
        },
        SET_SMSRESIDUE: (state, data) => {
            state.smsresidue = data
        },
        SET_VOICERESIDUE: (state, data) => {
            state.voiceresidue = data
        },
        SET_MAPTYPE: (state, data) => {
            sessionStorage.setItem("maptype", data)
            state.maptype = data
        },
        SET_LOGINID: (state, data) => {
            sessionStorage.setItem("loginid", data)
            state.loginid = data
        },
        SET_PENUM: (state, data) => {
            sessionStorage.setItem("penum", data)
            state.penum = data
        },
        SET_DISPLAYNAME: (state, data) => {
            sessionStorage.setItem("displayname", data)
            state.displayname = data
        },
        SET_HEADER_PEDETAIL: (state, data) => {
            sessionStorage.setItem("headerPedetail", JSON.stringify(data))
            state.headerPedetail = data
        },
        SET_LANG: (state, data) => {
            sessionStorage.setItem("lang", data.toLowerCase())
            // i18n.locale = data;

            if (data.toLowerCase() == 'en') {
                if (document.querySelector('body').className.indexOf('lang-en') == -1) {
                    document.querySelector('body').className += ' lang-en'
                }
            } else {
                document.querySelector('body').className = document.querySelector('body').className.replace('lang-en', '')
            }



            state.lang = data.toLowerCase()
        },
        SET_MENU_FUNTIONAUTH: (state, data) => {
            sessionStorage.setItem("menuFuntionAuth", JSON.stringify(data))
            state.menuFuntionAuth = data
        },
        SET_SELFREGISTER: (state, data) => {
            sessionStorage.setItem("selfregister", data)
            state.selfregister = data
        },
        SET_ISPEMGR: (state, data) => {
            state.ispemgr = data
        },
        SET_ISOPERATE: (state, data) => {
            state.isOperate = data
        },
        SET_UP_LIMIT: (state, data) => {
            sessionStorage.setItem("upLimit", JSON.stringify(data))
            state.upLimit = data
        },
        SET_AUTH_GROUP_FUNCLIST: (state, data) => {
            for (let n in state.authGroupFuncList) {
                state.authGroupFuncList[n] = false
            }

            data.forEach(item => {
                state.authGroupFuncList[`authgroup_${item.toLowerCase()}`] = true
            })
        },
        SET_SUFFIX: (state, data) => {
            state.suffix = data
        },
        SET_THEME: (state, data) => {
            localStorage.setItem('theme313fm', data)
            state.theme = data
        },
        SET_MENU_COLLAPSE: (state, data) => {
            sessionStorage.setItem('isMenuCollapse', data)
            state.isMenuCollapse = data
        },
        SET_FILTER_RECORDS_PAGE: (state, data) => {
            state.filterRecordsPage[data.name] = JSON.parse(JSON.stringify(data))
        },
        REMOVE_FILTER_RECORDS_PAGE: (state) => {
            state.filterRecordsPage = {}
        },
        SET_SAFETY_INCIDENT_FLASHING: (state, data) => {
            state.isSafetyIncidentFlashing = data
            sessionStorage.setItem('safetyIncidentFlashing', data)
        }
    },
    getters: {
        switch: state => state.switch,
        smsresidue: state => state.smsresidue,
        voiceresidue: state => state.voiceresidue,
        maptype: state => state.maptype,
        loginid: state => state.loginid,
        penum: state => state.penum,
        displayname: state => state.displayname,
        headerPedetail: (state) => {
            if (state.headerPedetail && typeof state.headerPedetail == 'string') {
                return JSON.parse(state.headerPedetail)
            } else {
                return state.headerPedetail
            }
        },
        lang: state => state.lang.toLowerCase(),
        menuFuntionAuth: (state) => (key) => {
            if (state.menuFuntionAuth) {
                if (typeof state.menuFuntionAuth == 'string') {
                    let menuFuntionAuth = JSON.parse(state.menuFuntionAuth)
                    return menuFuntionAuth[key]
                } else {
                    return state.menuFuntionAuth[key]
                }
            } else {
                return false
            }

            // return state.menuFuntionAuth[key]
        },
        selfregister: state => state.selfregister,
        ispemgr: state => state.ispemgr,
        isOperate: state => state.isOperate,
        upLimit: state => Number(state.upLimit),
        authGroupFuncList: (state) => (key) => {
            return state.authGroupFuncList[key]
        },
        suffix: state => state.suffix,
        // 判断是否demo
        isDemo: (state) => {
            if (state.penum) {
                return state.penum == 'PEDEMO';
            } else {
                return true;
            }
        },
        theme: state => state.theme,
        isMenuCollapse: state => {
            if (typeof state.isMenuCollapse === 'string') {
                return JSON.parse(state.isMenuCollapse)
            } else {
                return state.isMenuCollapse
            }
        },
        getFilterRecordsPage: (state) => (key) => {
            console.log('---state.filterRecordsPage', state.filterRecordsPage)
            return state.filterRecordsPage[key]
        },
        isSafetyIncidentFlashing: state => {
            if (typeof state.isSafetyIncidentFlashing === 'string') {
                return JSON.parse(state.isSafetyIncidentFlashing)
            } else {
                return state.isSafetyIncidentFlashing
            }
        },

    }
}

export default common